h1 {
	margin: 12px 0 20px;
}

.display-block {
	display: block;
}

.question {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 10px;
	margin-bottom: 20px;
	box-shadow: 0px 2px 7px 0px rgba(100, 100, 100, 0.2);

	.answers {
		display: flex;
		flex-wrap: wrap;

		.answer {
			border: 1px solid #ddd;
			border-radius: 3px;
			color: #fff;
			flex: 1 0 50%;
			opacity: 0.25;
			padding: 10px 20px;
			position: relative;

			img {
				position: absolute;
				height: 32px;
				width: auto;
				right: 7px;
				top: 7px;
			}

			&.correct {
				opacity: 1;
			}

			&:nth-child(1) {
				background: rgb(208, 25, 55);
			}

			&:nth-child(2) {
				background: rgb(19, 104, 206);
			}

			&:nth-child(3) {
				background: rgb(216, 158, 0);
			}

			&:nth-child(4) {
				background: rgb(38, 137, 12);
			}
		}
	}
}
